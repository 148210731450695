
import { defineComponent } from "vue";
import { SanityBlocks } from "sanity-blocks-vue-component";

export default defineComponent({
  props: {
    content: {
      type: Array,
      required: true,
    },
  },

  components: {
    SanityBlocks,
  },
});
