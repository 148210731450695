<template>
  <div class="content-block-text">
    <SanityBlocks :blocks="content" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SanityBlocks } from "sanity-blocks-vue-component";

export default defineComponent({
  props: {
    content: {
      type: Array,
      required: true,
    },
  },

  components: {
    SanityBlocks,
  },
});
</script>

<style lang="scss" scoped>
.content-block-text {
  margin: 3rem 0;
  @media (min-width) {
    margin: 4rem;
  }
}
</style>
